/*==============================================================================================================================
| Client        Moiré
| Project       Website
\=============================================================================================================================*/

/*==============================================================================================================================
| HIGHLIGHT NAVIGATION
\-----------------------------------------------------------------------------------------------------------------------------*/
/**
 * Determines where in the current page the user is based on their scroll position, and automatically highlights the associated
 * anchor in the navigation menu accordingly.
 */
function highlightNavigation(sections, navigationLinks, sectionIdToNavigationLink) {
  // get the current vertical position of the scroll bar
  var scrollPosition = $(window).scrollTop();

  // iterate the sections
  sections.each(function() {
    var currentSection = $(this);
    // get the position of the section
    var sectionTop = currentSection.offset().top;
    // if the user has scrolled over the  top of the section
    if (scrollPosition >= (sectionTop - 132)) {
      // get the section id
      var id = currentSection.attr('id');
      // get the corresponding navigation link
      var $navigationLink = sectionIdToNavigationLink[id];
      // if the link is not active
      if (!$navigationLink.hasClass('active')) {
        // remove .active class from all the links
        navigationLinks.removeClass('active');
        // add .active class to the current link
        $navigationLink.addClass('active');
      }
      // we have found our section, so we return false to exit the each loop
      return false;
    }
  });
}

/*==============================================================================================================================
| THROTTLE
\-----------------------------------------------------------------------------------------------------------------------------*/
/**
 * Enforces a minimum interval for reevaluating the scroll position in order to optimize performance, and especially on mobile
 * devices.
 */
function throttle(fn, interval) {
  var
    lastCall,
    timeoutId;

  return function () {
    var now = new Date().getTime();
    if (lastCall && now < (lastCall + interval) ) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function () {
        lastCall = now;
        fn.call();
      }, interval - (now - lastCall) );
    }
    else {
      lastCall = now;
      fn.call();
    }
  };
}

/*==============================================================================================================================
| ANIMATE MENU
\-----------------------------------------------------------------------------------------------------------------------------*/
/**
 * Allows the mobile menu to animate as it pulls out from the left side.
 */
function animateMenu() {
  if ($('#Navigation').hasClass('open')) {
    $('nav ul').animate({left: '0'}, 400);
    console.log('animating to left 0');
  }
  else {
    $('nav ul').animate({left: '-160px'}, 400);
    console.log('animating to left -160px');
  }
}

/*==============================================================================================================================
| JQUERY: WIRE UP ACTIONS
\-----------------------------------------------------------------------------------------------------------------------------*/
$(function() {

  /*----------------------------------------------------------------------------------------------------------------------------
  | Set variables
  \---------------------------------------------------------------------------------------------------------------------------*/
  var $sections                 = $($('section.pane').get().reverse());
  var $navigationLinks          = $('#Navigation > ul > li > a');

  /*----------------------------------------------------------------------------------------------------------------------------
  | Highlight on click
  \---------------------------------------------------------------------------------------------------------------------------*/
  $($navigationLinks).on('click', function() {
    $($navigationLinks).removeClass('active');
    $(this).addClass('active');
  });

  /*----------------------------------------------------------------------------------------------------------------------------
  | Map sections to their corresponding links
  \---------------------------------------------------------------------------------------------------------------------------*/
  var sectionIdToNavigationLink = {};
  $sections.each(function() {
    var id = $(this).attr('id');
    sectionIdToNavigationLink[id] = $('#Navigation > ul > li > a[href=\\#' + id + ']');
  });


  /*----------------------------------------------------------------------------------------------------------------------------
  | Highlight on scroll
  \---------------------------------------------------------------------------------------------------------------------------*/
  $(window).scroll(function() {
    if ($(window).scrollTop() >= 368) {
      highlightNavigation($sections, $navigationLinks, sectionIdToNavigationLink);
    }
    else {
      $navigationLinks.removeClass('active');
      return false;
    }
  });

  /*----------------------------------------------------------------------------------------------------------------------------
  | Pullout drawer (mobile)
  \---------------------------------------------------------------------------------------------------------------------------*/
  $('a.menu-hamburger').on('click', function(e) {
    e.preventDefault();
    $('nav').toggleClass('open').toggleClass('closed');
    animateMenu();
  });

  $('section, footer').on('click', function() {
    if ($('nav').hasClass('open')) {
      $('nav').toggleClass('open').toggleClass('closed');
    }
    animateMenu();
  });

});