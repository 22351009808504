/*==============================================================================================================================
| Client        Moiré
| Project       Website
\=============================================================================================================================*/

/*==============================================================================================================================
| JQUERY: WIRE UP ACTIONS
\-----------------------------------------------------------------------------------------------------------------------------*/
$(function() {

  /*----------------------------------------------------------------------------------------------------------------------------
  | Mobile Detection
  \---------------------------------------------------------------------------------------------------------------------------*/
  if (window.orientation !== undefined) {
    document.body.classList.add("mobile");
  }

});